export default defineNuxtPlugin((nuxt) => {
  nuxt.vueApp.directive("scroll", (el, binding) => {
    const f = (evt: any) => {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  });
});
