export default defineNuxtPlugin(() => {
  const popUp = {
    openWin: (url: string, name: string, property: string) => {
      const newWindow = window.open(url, name, property);
      if (newWindow) {
        newWindow.focus();
      }
    },
    helpWin: (url: string) => {
      const newWindow = window.open(
        url,
        "help",
        "toolbar=no,location=no,resizable=yes,status=no,scrollbars=yes,width=520,height=420,left=0,top=0"
      );
      if (newWindow) {
        newWindow.focus();
      }
    },
    browserWin: (url: string) => {
      const newWindow = window.open(
        url,
        "browser",
        "toolbar=no,location=no,resizable=yes,status=no,scrollbars=yes,width=500,height=500,left=0,top=0"
      );
      if (newWindow) {
        newWindow.focus();
      }
    },
    termWin: () => {
      const newWindow = window.open(
        "https://www.webcrew.co.jp/info/terms_pop_ins.html",
        "terms",
        "toolbar=no,location=no,resizable=yes,status=no,scrollbars=yes,width=520,height=500,left=0,top=0"
      );
      if (newWindow) {
        newWindow.focus();
      }
    },
    privacyWin: () => {
      const newWindow = window.open(
        "https://www.webcrew.co.jp/info/privacy_pop.html",
        "privacy",
        "toolbar=no,location=no,resizable=yes,status=no,scrollbars=yes,width=500,height=500,left=0,top=0"
      );

      if (newWindow) {
        newWindow.focus();
      }
    },
    cookieWin: () => {
      const newWindow = window.open(
        "https://www.bang.co.jp/cgi-bin/cookie/cookie_check.pl",
        "ins",
        "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,width=500,height=550"
      );
      if (newWindow) {
        newWindow.focus();
      }
    },
    browserCheckWin: () => {
      const newWindow = window.open(
        "https://www.webcrew.co.jp/browser/",
        "ins",
        "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,width=500,height=550"
      );
      if (newWindow) {
        newWindow.focus();
      }
    },
    ticketPrint: () => {
      window.print();
    },
  };
  return {
    provide: {
      popUp,
    },
  };
});
