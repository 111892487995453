import { default as _91id_93QFHRZhwuEPMeta } from "/fire-bang-frontend/src/pages/cate/[id].vue?macro=true";
import { default as mansion8jox3Q5tyrMeta } from "/fire-bang-frontend/src/pages/compare/mansion.vue?macro=true";
import { default as _91id_93jsbu2P5J51Meta } from "/fire-bang-frontend/src/pages/cont/[id].vue?macro=true";
import { default as indexdui6nFu1QBMeta } from "/fire-bang-frontend/src/pages/index.vue?macro=true";
import { default as indexwO0Ht4DUbmMeta } from "/fire-bang-frontend/src/pages/promo/landing/index.vue?macro=true";
import { default as index02SkBgzkoVSvMeta } from "/fire-bang-frontend/src/pages/promo/landing/index02.vue?macro=true";
import { default as index02_bui1QRYF4lXMeta } from "/fire-bang-frontend/src/pages/promo/landing/index02_b.vue?macro=true";
import { default as index03CDXtI9tQ0hMeta } from "/fire-bang-frontend/src/pages/promo/landing/index03.vue?macro=true";
import { default as index04OSdE7DVrKgMeta } from "/fire-bang-frontend/src/pages/promo/landing/index04.vue?macro=true";
import { default as index05EnpLV8JiwpMeta } from "/fire-bang-frontend/src/pages/promo/landing/index05.vue?macro=true";
import { default as index06niRBBoOePcMeta } from "/fire-bang-frontend/src/pages/promo/landing/index06.vue?macro=true";
import { default as index07Mt7zKyyXHsMeta } from "/fire-bang-frontend/src/pages/promo/landing/index07.vue?macro=true";
import { default as index081Y8w1kkFJNMeta } from "/fire-bang-frontend/src/pages/promo/landing/index08.vue?macro=true";
import { default as index10NHFYQbFFa6Meta } from "/fire-bang-frontend/src/pages/promo/landing/index10.vue?macro=true";
import { default as index11QKxFcovfRZMeta } from "/fire-bang-frontend/src/pages/promo/landing/index11.vue?macro=true";
import { default as index02ejIzlVs32vMeta } from "/fire-bang-frontend/src/pages/promo/landing03/index02.vue?macro=true";
import { default as index03qAjz154mw6Meta } from "/fire-bang-frontend/src/pages/promo/landing03/index03.vue?macro=true";
import { default as index2NdWg4ZnXVMeta } from "/fire-bang-frontend/src/pages/ranking/index.vue?macro=true";
import { default as sentry_45error_45testPk5JwduyiUMeta } from "/fire-bang-frontend/src/pages/sentry-error-test.vue?macro=true";
import { default as indexWpPdTLKrXTMeta } from "/fire-bang-frontend/src/pages/sitemap/index.vue?macro=true";
import { default as indexmrn0dkljZfMeta } from "/pages/promo/landing/index.vue?macro=true";
import { default as index023E2Feq6wMTMeta } from "/pages/promo/landing/index02.vue?macro=true";
import { default as index02_b5U3jxFlseVMeta } from "/pages/promo/landing/index02_b.vue?macro=true";
import { default as index03un9VECbkJjMeta } from "/pages/promo/landing/index03.vue?macro=true";
import { default as index04feJMJ7HkzpMeta } from "/pages/promo/landing/index04.vue?macro=true";
import { default as index05mBd4PiZzrrMeta } from "/pages/promo/landing/index05.vue?macro=true";
import { default as index068zdNi253EnMeta } from "/pages/promo/landing/index06.vue?macro=true";
import { default as index073fOsyXjDDvMeta } from "/pages/promo/landing/index07.vue?macro=true";
import { default as index08Kkicrrpr43Meta } from "/pages/promo/landing/index08.vue?macro=true";
import { default as index10RaX0YrQ6pMMeta } from "/pages/promo/landing/index10.vue?macro=true";
import { default as index113SGJ1vtW5HMeta } from "/pages/promo/landing/index11.vue?macro=true";
import { default as index02CFlM7d0y6OMeta } from "/pages/promo/landing03/index02.vue?macro=true";
import { default as index033PNaVxw40uMeta } from "/pages/promo/landing03/index03.vue?macro=true";
export default [
  {
    name: _91id_93QFHRZhwuEPMeta?.name ?? "cate-id",
    path: _91id_93QFHRZhwuEPMeta?.path ?? "/cate/:id()",
    meta: _91id_93QFHRZhwuEPMeta || {},
    alias: _91id_93QFHRZhwuEPMeta?.alias || [],
    redirect: _91id_93QFHRZhwuEPMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/cate/[id].vue").then(m => m.default || m)
  },
  {
    name: mansion8jox3Q5tyrMeta?.name ?? "compare-mansion",
    path: mansion8jox3Q5tyrMeta?.path ?? "/compare/mansion",
    meta: mansion8jox3Q5tyrMeta || {},
    alias: mansion8jox3Q5tyrMeta?.alias || [],
    redirect: mansion8jox3Q5tyrMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/compare/mansion.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jsbu2P5J51Meta?.name ?? "cont-id",
    path: _91id_93jsbu2P5J51Meta?.path ?? "/cont/:id()",
    meta: _91id_93jsbu2P5J51Meta || {},
    alias: _91id_93jsbu2P5J51Meta?.alias || [],
    redirect: _91id_93jsbu2P5J51Meta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/cont/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdui6nFu1QBMeta?.name ?? "index",
    path: indexdui6nFu1QBMeta?.path ?? "/",
    meta: indexdui6nFu1QBMeta || {},
    alias: indexdui6nFu1QBMeta?.alias || [],
    redirect: indexdui6nFu1QBMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwO0Ht4DUbmMeta?.name ?? "promo-landing",
    path: indexwO0Ht4DUbmMeta?.path ?? "/promo/landing",
    meta: indexwO0Ht4DUbmMeta || {},
    alias: indexwO0Ht4DUbmMeta?.alias || [],
    redirect: indexwO0Ht4DUbmMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index.vue").then(m => m.default || m)
  },
  {
    name: index02SkBgzkoVSvMeta?.name ?? "promo-landing-index02",
    path: index02SkBgzkoVSvMeta?.path ?? "/promo/landing/index02",
    meta: index02SkBgzkoVSvMeta || {},
    alias: index02SkBgzkoVSvMeta?.alias || [],
    redirect: index02SkBgzkoVSvMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index02.vue").then(m => m.default || m)
  },
  {
    name: index02_bui1QRYF4lXMeta?.name ?? "promo-landing-index02_b",
    path: index02_bui1QRYF4lXMeta?.path ?? "/promo/landing/index02_b",
    meta: index02_bui1QRYF4lXMeta || {},
    alias: index02_bui1QRYF4lXMeta?.alias || [],
    redirect: index02_bui1QRYF4lXMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index02_b.vue").then(m => m.default || m)
  },
  {
    name: index03CDXtI9tQ0hMeta?.name ?? "promo-landing-index03",
    path: index03CDXtI9tQ0hMeta?.path ?? "/promo/landing/index03",
    meta: index03CDXtI9tQ0hMeta || {},
    alias: index03CDXtI9tQ0hMeta?.alias || [],
    redirect: index03CDXtI9tQ0hMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index03.vue").then(m => m.default || m)
  },
  {
    name: index04OSdE7DVrKgMeta?.name ?? "promo-landing-index04",
    path: index04OSdE7DVrKgMeta?.path ?? "/promo/landing/index04",
    meta: index04OSdE7DVrKgMeta || {},
    alias: index04OSdE7DVrKgMeta?.alias || [],
    redirect: index04OSdE7DVrKgMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index04.vue").then(m => m.default || m)
  },
  {
    name: index05EnpLV8JiwpMeta?.name ?? "promo-landing-index05",
    path: index05EnpLV8JiwpMeta?.path ?? "/promo/landing/index05",
    meta: index05EnpLV8JiwpMeta || {},
    alias: index05EnpLV8JiwpMeta?.alias || [],
    redirect: index05EnpLV8JiwpMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index05.vue").then(m => m.default || m)
  },
  {
    name: index06niRBBoOePcMeta?.name ?? "promo-landing-index06",
    path: index06niRBBoOePcMeta?.path ?? "/promo/landing/index06",
    meta: index06niRBBoOePcMeta || {},
    alias: index06niRBBoOePcMeta?.alias || [],
    redirect: index06niRBBoOePcMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index06.vue").then(m => m.default || m)
  },
  {
    name: index07Mt7zKyyXHsMeta?.name ?? "promo-landing-index07",
    path: index07Mt7zKyyXHsMeta?.path ?? "/promo/landing/index07",
    meta: index07Mt7zKyyXHsMeta || {},
    alias: index07Mt7zKyyXHsMeta?.alias || [],
    redirect: index07Mt7zKyyXHsMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index07.vue").then(m => m.default || m)
  },
  {
    name: index081Y8w1kkFJNMeta?.name ?? "promo-landing-index08",
    path: index081Y8w1kkFJNMeta?.path ?? "/promo/landing/index08",
    meta: index081Y8w1kkFJNMeta || {},
    alias: index081Y8w1kkFJNMeta?.alias || [],
    redirect: index081Y8w1kkFJNMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index08.vue").then(m => m.default || m)
  },
  {
    name: index10NHFYQbFFa6Meta?.name ?? "promo-landing-index10",
    path: index10NHFYQbFFa6Meta?.path ?? "/promo/landing/index10",
    meta: index10NHFYQbFFa6Meta || {},
    alias: index10NHFYQbFFa6Meta?.alias || [],
    redirect: index10NHFYQbFFa6Meta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index10.vue").then(m => m.default || m)
  },
  {
    name: index11QKxFcovfRZMeta?.name ?? "promo-landing-index11",
    path: index11QKxFcovfRZMeta?.path ?? "/promo/landing/index11",
    meta: index11QKxFcovfRZMeta || {},
    alias: index11QKxFcovfRZMeta?.alias || [],
    redirect: index11QKxFcovfRZMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing/index11.vue").then(m => m.default || m)
  },
  {
    name: index02ejIzlVs32vMeta?.name ?? "promo-landing03-index02",
    path: index02ejIzlVs32vMeta?.path ?? "/promo/landing03/index02",
    meta: index02ejIzlVs32vMeta || {},
    alias: index02ejIzlVs32vMeta?.alias || [],
    redirect: index02ejIzlVs32vMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing03/index02.vue").then(m => m.default || m)
  },
  {
    name: index03qAjz154mw6Meta?.name ?? "promo-landing03-index03",
    path: index03qAjz154mw6Meta?.path ?? "/promo/landing03/index03",
    meta: index03qAjz154mw6Meta || {},
    alias: index03qAjz154mw6Meta?.alias || [],
    redirect: index03qAjz154mw6Meta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/promo/landing03/index03.vue").then(m => m.default || m)
  },
  {
    name: index2NdWg4ZnXVMeta?.name ?? "ranking",
    path: index2NdWg4ZnXVMeta?.path ?? "/ranking",
    meta: index2NdWg4ZnXVMeta || {},
    alias: index2NdWg4ZnXVMeta?.alias || [],
    redirect: index2NdWg4ZnXVMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/ranking/index.vue").then(m => m.default || m)
  },
  {
    name: sentry_45error_45testPk5JwduyiUMeta?.name ?? "sentry-error-test",
    path: sentry_45error_45testPk5JwduyiUMeta?.path ?? "/sentry-error-test",
    meta: sentry_45error_45testPk5JwduyiUMeta || {},
    alias: sentry_45error_45testPk5JwduyiUMeta?.alias || [],
    redirect: sentry_45error_45testPk5JwduyiUMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/sentry-error-test.vue").then(m => m.default || m)
  },
  {
    name: indexWpPdTLKrXTMeta?.name ?? "sitemap",
    path: indexWpPdTLKrXTMeta?.path ?? "/sitemap",
    meta: indexWpPdTLKrXTMeta || {},
    alias: indexWpPdTLKrXTMeta?.alias || [],
    redirect: indexWpPdTLKrXTMeta?.redirect || undefined,
    component: () => import("/fire-bang-frontend/src/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: indexmrn0dkljZfMeta?.name ?? "lpUrl-01",
    path: indexmrn0dkljZfMeta?.path ?? "/promo/landing/",
    meta: indexmrn0dkljZfMeta || {},
    alias: indexmrn0dkljZfMeta?.alias || [],
    redirect: indexmrn0dkljZfMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index.vue").then(m => m.default || m)
  },
  {
    name: index023E2Feq6wMTMeta?.name ?? "lpUrl-02",
    path: index023E2Feq6wMTMeta?.path ?? "/promo/landing/index02.html",
    meta: index023E2Feq6wMTMeta || {},
    alias: index023E2Feq6wMTMeta?.alias || [],
    redirect: index023E2Feq6wMTMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index02.vue").then(m => m.default || m)
  },
  {
    name: index02_b5U3jxFlseVMeta?.name ?? "lpUrl-02-b",
    path: index02_b5U3jxFlseVMeta?.path ?? "/promo/landing/index02_b.html",
    meta: index02_b5U3jxFlseVMeta || {},
    alias: index02_b5U3jxFlseVMeta?.alias || [],
    redirect: index02_b5U3jxFlseVMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index02_b.vue").then(m => m.default || m)
  },
  {
    name: index03un9VECbkJjMeta?.name ?? "lpUrl-03",
    path: index03un9VECbkJjMeta?.path ?? "/promo/landing/index03.html",
    meta: index03un9VECbkJjMeta || {},
    alias: index03un9VECbkJjMeta?.alias || [],
    redirect: index03un9VECbkJjMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index03.vue").then(m => m.default || m)
  },
  {
    name: index04feJMJ7HkzpMeta?.name ?? "lpUrl-04",
    path: index04feJMJ7HkzpMeta?.path ?? "/promo/landing/index04.html",
    meta: index04feJMJ7HkzpMeta || {},
    alias: index04feJMJ7HkzpMeta?.alias || [],
    redirect: index04feJMJ7HkzpMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index04.vue").then(m => m.default || m)
  },
  {
    name: index05mBd4PiZzrrMeta?.name ?? "lpUrl-05",
    path: index05mBd4PiZzrrMeta?.path ?? "/promo/landing/index05.html",
    meta: index05mBd4PiZzrrMeta || {},
    alias: index05mBd4PiZzrrMeta?.alias || [],
    redirect: index05mBd4PiZzrrMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index05.vue").then(m => m.default || m)
  },
  {
    name: index068zdNi253EnMeta?.name ?? "lpUrl-06",
    path: index068zdNi253EnMeta?.path ?? "/promo/landing/index06.html",
    meta: index068zdNi253EnMeta || {},
    alias: index068zdNi253EnMeta?.alias || [],
    redirect: index068zdNi253EnMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index06.vue").then(m => m.default || m)
  },
  {
    name: index073fOsyXjDDvMeta?.name ?? "lpUrl-07",
    path: index073fOsyXjDDvMeta?.path ?? "/promo/landing/index07.html",
    meta: index073fOsyXjDDvMeta || {},
    alias: index073fOsyXjDDvMeta?.alias || [],
    redirect: index073fOsyXjDDvMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index07.vue").then(m => m.default || m)
  },
  {
    name: index08Kkicrrpr43Meta?.name ?? "lpUrl-08",
    path: index08Kkicrrpr43Meta?.path ?? "/promo/landing/index08.html",
    meta: index08Kkicrrpr43Meta || {},
    alias: index08Kkicrrpr43Meta?.alias || [],
    redirect: index08Kkicrrpr43Meta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index08.vue").then(m => m.default || m)
  },
  {
    name: index10RaX0YrQ6pMMeta?.name ?? "lpUrl-10",
    path: index10RaX0YrQ6pMMeta?.path ?? "/promo/landing/index10.html",
    meta: index10RaX0YrQ6pMMeta || {},
    alias: index10RaX0YrQ6pMMeta?.alias || [],
    redirect: index10RaX0YrQ6pMMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index10.vue").then(m => m.default || m)
  },
  {
    name: index113SGJ1vtW5HMeta?.name ?? "lpUrl-11",
    path: index113SGJ1vtW5HMeta?.path ?? "/promo/landing/index11.html",
    meta: index113SGJ1vtW5HMeta || {},
    alias: index113SGJ1vtW5HMeta?.alias || [],
    redirect: index113SGJ1vtW5HMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing/index11.vue").then(m => m.default || m)
  },
  {
    name: index02CFlM7d0y6OMeta?.name ?? "lpUrl-03-02",
    path: index02CFlM7d0y6OMeta?.path ?? "/promo/landing03/index02.html",
    meta: index02CFlM7d0y6OMeta || {},
    alias: index02CFlM7d0y6OMeta?.alias || [],
    redirect: index02CFlM7d0y6OMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing03/index02.vue").then(m => m.default || m)
  },
  {
    name: index033PNaVxw40uMeta?.name ?? "lpUrl-03-03",
    path: index033PNaVxw40uMeta?.path ?? "/promo/landing03/index03.html",
    meta: index033PNaVxw40uMeta || {},
    alias: index033PNaVxw40uMeta?.alias || [],
    redirect: index033PNaVxw40uMeta?.redirect || undefined,
    component: () => import("/pages/promo/landing03/index03.vue").then(m => m.default || m)
  }
]