/**
 * リクエスト先FQDNを取得する
 * useFetch利用時、リクエスト先URLにFQDNを指定しないと、SSR時にエラーが発生する。(恐らく内部の不具合)
 * その為、SSRでは内部ホスト http://localhost:3000/* に、CSR時はドメイン指定を省略 /* に、リクエスト先URLを変更する必要がある。
 */
export default defineNuxtPlugin(() => {
    const { fireBangApiUrl, commonApiUrl } = useRuntimeConfig()
    return {
        provide: {
            fireBangApiDomain: process.server ? fireBangApiUrl : '/firebang-api',
            commonApiDomain: process.server ? commonApiUrl : '/common-api',
        }
    }
})