import revive_payload_client_Frd8gASYWa from "/fire-bang-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/fire-bang-frontend/src/.nuxt/components.plugin.mjs";
import unhead_3Bi0E2Ktsf from "/fire-bang-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/fire-bang-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5ZRYOjuei0 from "/fire-bang-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_UWUxmn85Fk from "/fire-bang-frontend/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/fire-bang-frontend/src/.nuxt/sentry-client-config.mjs";
import plugin_L4dx7UUNO5 from "/fire-bang-frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_CHalsvJBex from "/fire-bang-frontend/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import chunk_reload_client_kCUITUWXUN from "/fire-bang-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import scroll_S7pZiC3d46 from "/fire-bang-frontend/src/plugins/scroll.ts";
import dayjs_pGiXRjcsJO from "/fire-bang-frontend/src/plugins/dayjs.ts";
import backfaceFixed_Y2pFvL64uN from "/fire-bang-frontend/src/plugins/backfaceFixed.ts";
import apiDomain_0F8UKx45ha from "/fire-bang-frontend/src/plugins/apiDomain.ts";
import logger_kEXgbufg9p from "/fire-bang-frontend/src/plugins/logger.ts";
import popup_client_q4kfBHFTWY from "/fire-bang-frontend/src/plugins/popup.client.ts";
import vue_gtm_client_stBjLl0OeM from "/fire-bang-frontend/src/plugins/vue-gtm.client.ts";
import vue3_smooth_scroll_PasLkR9Jxu from "/fire-bang-frontend/src/plugins/vue3-smooth-scroll.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  components_plugin_KR1HBZs4kY,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  prefetch_client_5ZRYOjuei0,
  sentry_client_UWUxmn85Fk,
  sentry_client_config_o34nk2sJbg,
  plugin_L4dx7UUNO5,
  plugin_CHalsvJBex,
  chunk_reload_client_kCUITUWXUN,
  scroll_S7pZiC3d46,
  dayjs_pGiXRjcsJO,
  backfaceFixed_Y2pFvL64uN,
  apiDomain_0F8UKx45ha,
  logger_kEXgbufg9p,
  popup_client_q4kfBHFTWY,
  vue_gtm_client_stBjLl0OeM,
  vue3_smooth_scroll_PasLkR9Jxu
]