export default defineNuxtRouteMiddleware((to, from) => {
    // クエリパラメーターに'ID'が存在している場合のみパラメータを小文字に変換する
    if ('ID' in to.query) {
      const lowerCaseQuery: Record<string, string> = {}
      for (const key in to.query) {
        if (Object.prototype.hasOwnProperty.call(to.query, key)) {
          const value = to.query[key];
          // to.query[key]がnullでなく、undefinedでないことを確認する
          if (value !== null && typeof value !== 'undefined') {
            // to.query[key]がstring型であることを保証する
            if (typeof value === 'string' || value instanceof String) {
              lowerCaseQuery[key.toLowerCase()] = value.toString();
            }
          }
        }
      }
      to.query = lowerCaseQuery;
    }

    // to.query.idがstring型であることを保証する
    const id = typeof to.query.id === 'string' ? to.query.id : '';

    const promCode = useCookie('promCode', {
      maxAge: 1000 * 60 * 60 * 24 * 365,
      httpOnly: true,
      secure: false
    })

    if (id) {
      promCode.value = id;
    }
    if(!promCode.value) {
      promCode.value = 'aaaaa00000'
    }
})